import GridLayout from "react-grid-layout";
import React, { useState, useEffect } from "react";
import { Segment, Image, Divider, Header } from 'semantic-ui-react'



const img1 = './images/g1069.jpg'
const img2 = './images/la2.jpg'
const img3 = './images/pic3.jpg'
const img4 = './images/pic2.jpg'

export function Product() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isMobile = width <= 768;

    const desktopLayout = [
        { i: "a1", x: 2, y: 0, w: 6, static: true },

    ];

    const mobileLayout = [
        { i: "a1", x: 1, y: 0, w: 10, static: true },
        
    ];


    const layout = isMobile ? mobileLayout : desktopLayout;



    return (
        <GridLayout
            layout={layout}
            cols={12}
            rowHeight={100}
            width={width}
        >

            <div key="a1">
                <Header as='h1'>Product Information</Header>
                <Segment>
                    <br/>
                    <Header as='h3' textAlign="center">LA20 - TDLAS Moisture Transmitter</Header>
                    <Image src={img1} size='huge' centered />
                    <br />
                    <p>
                        The Process Moisture Measurement Solution. The LA2 offers a cost
                        effective
                        solution for reliable, process moisture analysis. Utilizing our time- proven
                        electrolytic
                        technology, it features new micro- processor driven electronics.
                        <a href="./product/LA2_EN.pdf">&nbsp;&nbsp; check details <span>{">"}</span></a>
                    </p>

                    <Divider />

                    <Header as='h3' textAlign="center">TPM500 - Portable Moisture Analyzer</Header>
                    <Image src={img2} size='huge' centered />
                    <br />
                    <p>
                        The Portable Moisture Measurement Solution. The TPM500 offers a cost
                        effective
                        solution for reliable, portablemoisture analysis. Utilizing our time- proven
                        electrolytic
                        technology, it features new micro- processor driven electronics.
                        <a href="./product/TPM500_EN.pdf">&nbsp;&nbsp; check details <span>{">"}</span></a>
                    </p>

                    <Divider />

                    <Header as='h3' textAlign="center">TP201 - Dew point sensor from -80 to 20 °Ctd</Header>
                    <Image src={img3} size='huge' centered />
                    <br />
                    <p>
                        TP201 is the ideal dew point measuring instrument with integrated
                        display and
                        alarm re- lay for refrigeration, membrane and adsorption dryers.
                        <a href="./product/TP201_EN.pdf">&nbsp;&nbsp; check details <span>{">"}</span></a>
                    </p>

                    <Divider />


                    <Header as='h3' textAlign="center">TP20 - Dew point sensor</Header>
                    <Image src={img4} size='huge' centered />
                    <br />
                    <p>
                        TP20 - the new generation of dew point sensors for residual moisture
                        measurement in compressed air and gases
                        <a href=".product/TP20_EN.pdf">&nbsp;&nbsp; check details <span>{">"}</span></a>
                    </p>




                </Segment>
            </div>
        </GridLayout>

    )
}

