import GridLayout from "react-grid-layout";
import React, { useState, useEffect } from "react";
import { Segment, Image, Divider} from 'semantic-ui-react'



const img1 = './images/faj1.jpg'

export function Company() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const isMobile = width <= 768;

    const desktopLayout = [
        { i: "a1", x: 2, y: 0, w: 6, static: true },

    ];

    const mobileLayout = [
        { i: "a1", x: 1, y: 0, w: 10, static: true },
        
    ];


    const layout = isMobile ? mobileLayout : desktopLayout;




    return (
        <GridLayout
            layout={layout}
            cols={12}
            rowHeight={100}
            width={width}
        >

            <div key="a1">

                <Segment>
                    <Image src={img1} size='huge' centered />
                    <h1>About US</h1>
                    <Divider />
                    <p>
                    MOH Technologies LLC is a dynamic and vibrant company headquartered in New Jersey. As an authorized
                    distributor of various gas sensors and detection instruments, MOH Technologies LLC has established
                    itself as a prominent player in the industry.

                    With a young and energetic team, the company is dedicated to providing top-quality products and
                    services to its customers. The range of gas sensors and detection instruments offered by MOH
                    Technologies LLC is diverse, catering to a wide range of industries and applications.

                    The company's commitment to excellence is evident through its partnerships with leading
                    manufacturers in the field. By collaborating with these industry giants, MOH Technologies LLC
                    ensures that its customers have access to cutting-edge technologies and innovative solutions.

                    Moreover, the company's headquarters in New Jersey provides a strategic location for efficient
                    operations and customer support. The team at MOH Technologies LLC is well-equipped to handle
                    customer inquiries and provide timely assistance, ensuring a seamless experience for clients.

                    In addition to its focus on customer satisfaction, MOH Technologies LLC also places great importance
                    on staying up-to-date with the latest advancements in gas sensing and detection technologies. The
                    company actively engages in research and development activities to continuously improve its product
                    offerings and stay ahead of the competition.

                    In conclusion, MOH Technologies LLC is a young and vibrant company that serves as a trusted
                    distributor of gas sensors and detection instruments. With its headquarters in New Jersey and a
                    dedicated team, the company is well-positioned to meet the needs of its customers and provide them
                    with high-quality products and services.
                    </p>
                </Segment>
            </div>
        </GridLayout>

    )
}

