import styles from './tops.module.css'
import { useNavigate } from "react-router";
import { Image } from 'semantic-ui-react'
import { useState, useEffect } from 'react';
import GridLayout from "react-grid-layout";

export function TopS() {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const isMobile = width <= 768;

    const desktopLayout = [
        { i: "a1", x: 2, y: 1, w: 9, h: 0.65, static: true },
        { i: "a2", x: 0, y: 0, w: 2, h: 0.65, static: true },

    ];

    const mobileLayout = [
        { i: "a1", x: 2, y: 0, w: 9, h: 0.1, static: true },
        { i: "a2", x: 0, y: 0.1, w: 2, h: 0.1, static: true },
        
    ];


    const layout = isMobile ? mobileLayout : desktopLayout;




    return <div tyle={{ width: "100%" }}>
        <GridLayout
            layout={layout}
            cols={12}
            rowHeight={25}
            width={width}
        >
            <div key="a1" className={styles.tops}>
                <ul>

                    <li onClick={() => { navigate("company") }} >Company</li>
                    <li onClick={() => { navigate("product") }} >Product</li>
                    <li onClick={() => { navigate("") }} >Home</li>
                </ul>
            </div>
            <div key="a2" tyle={{ width: "100%" }}>
                <Image className={styles.img1} src='./images/logo.png' size='medium' floated="left" />
            </div>
        </GridLayout>
    </div>
}