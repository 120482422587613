import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';


export function ImgG3() {
  return (
    <Splide options={{ rewind: true }} aria-label="React Splide Example">
      <SplideSlide>
        <img src="./images/la2.jpg" alt="img1" />
      </SplideSlide>
      <SplideSlide>
        <img src="./images/g1069.jpg" alt="img2" />
      </SplideSlide>
      <SplideSlide>
        <img src="./images/pic2.jpg" alt="img3" />
      </SplideSlide>
      <SplideSlide>
        <img src="./images/pic3.jpg" alt="img4" />
      </SplideSlide>
    </Splide>
  );
}