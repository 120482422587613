import { HashRouter ,Routes, Route } from "react-router-dom";
import { Index }  from "./containers/index";
import { Home }  from "./containers/home";
import { Company } from "./containers/company";
import { Product } from "./containers/product";


function App(){
  return <HashRouter>
      <Routes>
          <Route path="" element={<Index />} >
            <Route path="" element={<Home />} />
            <Route path="company" element={<Company />} />
            <Route path="product" element={<Product />} />
          </Route>
          
      </Routes>
  </HashRouter> 
}


export default App;
