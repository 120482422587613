
import GridLayout from "react-grid-layout";
import { Outlet } from "react-router";
import React, { useState, useEffect } from "react";
import { TopS } from './tops';


export function Index() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    const isMobile = width <= 768;

    const desktopLayout = [
        { i: "a1", x: 0, y: 0, w: 12, h: 2, static: true},
        { i: "b1", x: 1, y: 2, w: 10, h: 12, static: true}
      ];
    
    const mobileLayout = [
        { i: "a1", x: 0, y: 0, w: 2.5, h: 0.5, static: true},
        { i: "b1", x: 0, y: 1.5, w: 8, h: 12, static: true},
      ];


    const layout = isMobile ? mobileLayout : desktopLayout;


    return <div style={{ width: "100%" }}>
        <GridLayout
            layout={layout}
            cols={12}
            rowHeight={100}
            width={width}
        >
            <div key="a1" >
                <TopS/>
            </div>
            <div key="b1">
                <Outlet/>
            </div>
        </GridLayout>
    </div>
}

